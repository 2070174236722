import axios from "axios";
import { Api } from "./ApiFactory";
import { GLOBAL_CONST } from "../../constants";

export class GoBosApi implements Api {
  getAuthHeader = () => {
    return {
      headers: {
        "x-access-token": GLOBAL_CONST.ACCESS_TOKEN,
      },
    };
  };

  updateTcAcceptanceForShellRewards = async () => {
    await axios.post(
      `${GOBOS_API_ENDPOINT}/accounts/v1/preferences`,
      {
        shellRewardsTcAgreement: "ACCEPTED",
      },
      this.getAuthHeader(),
    );
  };

  getCouponForShellRewards = async (): Promise<string> => {
    const response = await axios.get(`${GOBOS_API_ENDPOINT}/rewards/shell/v1/coupon`, {
      ...this.getAuthHeader(),
      params: { conditionsAccepted: true },
    });
    return response.data.barcodeId;
  };
}
