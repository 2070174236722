import axios, { AxiosInstance } from "axios";
import { z } from "zod";
import { GLOBAL_CONST } from "../../constants";

const RewardsSchema = z.object({
  id: z.string(),
  logoUrl: z.string(),
  subtitleText: z.string(),
  thumbnailUrl: z.string(),
  thumbnailUrlSvg: z.string(),
  titleText: z.string(),
  webviewUrl: z.string(),
  pillText: z.string().optional(),
  placard: z.boolean().optional(),
});

const CouponSchema = z.object({
  couponCode: z.string(),
});

type Rewards = z.infer<typeof RewardsSchema>;
type Coupon = z.infer<typeof CouponSchema>;

export interface GetCouponProps {
  accountId: string;
  offerId: string;
}

export class RewardsHubServicesApi {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: REWARDS_HUB_SERVICES_ENDPOINT,
      headers: {
        "x-api-key": REWARDS_HUB_SERVICES_API_KEY,
        Authorization: `Bearer ${GLOBAL_CONST.ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
    });
  }

  private handleErr(err: any) {
    if (axios.isAxiosError(err)) {
      console.error("Rewards Hub Services API error:", err.response?.data || err.message);
    } else {
      console.error("Rewards Hub Services unexpected error:", err);
    }
  }

  public async getRewards(id: string): Promise<Rewards> {
    try {
      const res = await this.axiosInstance.get("/v1/rewards", { params: { id } });
      return RewardsSchema.parse(res.data);
    } catch (err) {
      this.handleErr(err);
      throw err;
    }
  }

  public async getCoupon(props: GetCouponProps): Promise<Coupon> {
    try {
      const res = await this.axiosInstance.get("/v1/coupon", { params: { ...props } });
      return CouponSchema.parse(res.data);
    } catch (err) {
      this.handleErr(err);
      throw err;
    }
  }
}
