/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import parse from "html-react-parser";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import RewardsButton from "../../common/components/RewardsButton";
import { REWARD_OFFERING } from "../../constants";
import { fromPublic, getPlatformFormattedText } from "../../utils";

const StyledTransitionWrapper = styled.div`
  .transition {
    &-enter {
      transform: translateY(100%);
    }

    &-enter-active {
      transform: translateY(0);
      transition: transform 200ms ease-out;
    }

    &-enter-done {
      transform: translateY(0);
    }

    &-exit {
      transform: translateY(0);
    }

    &-exit-active {
      transform: translateY(100%);
      transition: transform 200ms ease-in;
    }
    &-exit-done {
      transform: translateY(100%);
    }
  }
`;

const StyledRedeemHintCard = styled.div`
  position: fixed;
  width: 100%;
  flex-direction: column;
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  transform: translateY(100%); // Hide the card so it's ready for transition.
  bottom: 0;
`;

const StyledContent = styled.div`
  padding: 16px;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;

  img {
    margin-top: 12px;
  }

  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(RewardsButton)`
  margin-top: 18px;
  margin-bottom: 18px;
`;

function RedeemHintCard({ hintOnClick, className, showHint }: any) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <StyledTransitionWrapper>
      <CSSTransition in={showHint} timeout={2000} classNames="transition" nodeRef={ref}>
        <StyledRedeemHintCard ref={ref} className={className}>
          <StyledContent>
            {parse(GLOBAL_CFG[REWARD_OFFERING.shellfuelup].redeem.hintCard.title)}
            <img src={fromPublic(GLOBAL_CFG[REWARD_OFFERING.shellfuelup].redeem.hintCard.image)} />
            {parse(GLOBAL_CFG[REWARD_OFFERING.shellfuelup].redeem.hintCard.content)}
            <StyledButton clickHandler={hintOnClick}>{getPlatformFormattedText("Got it")}</StyledButton>
          </StyledContent>
        </StyledRedeemHintCard>
      </CSSTransition>
    </StyledTransitionWrapper>
  );
}

export default RedeemHintCard;
