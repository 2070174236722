import { ErrorInfo } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import App from "./App";
import { EventLogger } from "./common/utils/EventLogger";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const onError = (error: Error, info: ErrorInfo) => {
  console.log(`error = ${error}, errorInfo = ${JSON.stringify(info, null, 2)}`);
  EventLogger.logErrorEvent("ErrorBoundary: Fatal error occurred", error);
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary fallback={<div />} onError={onError}>
    <RecoilRoot>
      <RecoilNexus />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RecoilRoot>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
