/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import Lottie from "lottie-react";
import { useState } from "react";
import { fromPublic, getLottieJson } from "../../utils";

const FullScreenImage = styled.div`
  position: absolute;
  z-index: 100;
`;

function ImageLottie({ imageLottie, fullScreen = false, loop = false }: any) {
  const [isLottieVisible, setIsLottieVisible] = useState(true);
  if (!imageLottie) {
    return null;
  }

  const animationData = getLottieJson(fromPublic(imageLottie));
  const handleLottieAnimationComplete = () => {
    setIsLottieVisible(false);
  };

  return fullScreen ? ( // Full Screen will hide the image when the lottie animation is complete
    isLottieVisible ? (
      <FullScreenImage>
        <Lottie animationData={animationData} loop={loop} onComplete={handleLottieAnimationComplete} />
      </FullScreenImage>
    ) : (
      <></>
    )
  ) : (
    <Lottie animationData={animationData} loop={loop} />
  );
}

export default ImageLottie;
