import axios from "axios";
import { GLOBAL_CONST } from "../../constants";
import { Api } from "./ApiFactory";

export class BffApi implements Api {
  getAuthHeader = () => {
    return {
      headers: {
        Authorization: `Bearer ${GLOBAL_CONST.ACCESS_TOKEN}`,
        "person-id": GLOBAL_CONST.DATA.personId,
        "bff-os": GLOBAL_CONST.DATA["os"],
        "bff-app-name": GLOBAL_CONST.DATA["appName"],
        "bff-app-version": GLOBAL_CONST.DATA["appVersion"],
        "bff-os-version": GLOBAL_CONST.DATA["osVersion"],
        "client-installation-id": GLOBAL_CONST.DATA["clientInstallId"],
      },
    };
  };

  updateTcAcceptanceForShellRewards = async () => {
    // do nothing - not needed for BFF. ts&Cs are recorded via the redeem coupon call
  };

  getCouponForShellRewards = async (params?: { acceptedTerms?: boolean; rewardId?: string }): Promise<string> => {
    const response = await axios.get(
      `${BFF_API_ENDPOINT}/rewards/v1/shell/accounts/${GLOBAL_CONST.DATA.accountId}/redeem`,
      {
        ...this.getAuthHeader(),
        params: {
          ...(params?.acceptedTerms ? { conditionsAccepted: params.acceptedTerms } : {}),
          ...(params?.rewardId ? { rewardId: params.rewardId } : {}), // for Shell Fuel Up Your Savings compaign
        },
      },
    );
    return response.data.barcodeId;
  };
}
