import axios from "axios";
import { GLOBAL_CONST } from "../../constants";
import { Api } from "./ApiFactory";

export class TuapiApi implements Api {
  getAuthHeader = () => {
    return {
      headers: {
        Authorization: `Bearer ${GLOBAL_CONST.ACCESS_TOKEN}`,
        "client-installation-id": GLOBAL_CONST.DATA["clientInstallId"],
        "client-version": GLOBAL_CONST.DATA["appVersion"],
      },
    };
  };

  updateTcAcceptanceForShellRewards = async () => {
    // do nothing - not needed for TUAPI
  };

  getCouponForShellRewards = async (params?: { rewardId?: string }): Promise<string> => {
    const response = await axios.get(
      `${TUAPI_API_ENDPOINT}/v2/rewards/shell/accounts/${GLOBAL_CONST.DATA.accountId}/redeem`,
      {
        ...this.getAuthHeader(),
        params: {
          ...{ conditionsAccepted: true }, // why is this hardcoded for nsw & qld users?
          ...(params?.rewardId ? { rewardId: params.rewardId } : {}), // for Shell Fuel Up Your Savings compaign
        },
      },
    );
    return response.data.payload.barcodeId;
  };
}
