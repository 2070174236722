import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { GLOBAL_CONST, REWARD_OFFERING } from "../../constants";

function Comp1000PrizesMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={`https://www.linkt.com.au/campaigns/winrewards?region=${GLOBAL_CONST.REGION}${
            GLOBAL_CONST.DATA["externalId"] ? "&cid=" + GLOBAL_CONST.DATA["externalId"] : ""
          }`}
          rewardId={REWARD_OFFERING.comp1000prizes}
        />
      </span>
    </>
  );
}

export default Comp1000PrizesMain;
