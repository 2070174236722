/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  delay,
  enableBackgroundOverlay,
  getPlatformFormattedText,
  redirect,
  redirectError,
  redirectLoaded,
  replaceGlobalConfigVars,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";
import OnboardingStep from "../../common/components/OnboardingStep";
import SwiperDots from "../../common/components/SwiperDots";
import { Swiper as SwiperClass } from "swiper/types";
import parse from "html-react-parser";
import RewardsButton from "../../common/components/RewardsButton";
import OnboardingContent from "../../common/components/OnboardingContent";
import OnboardingCTA from "../../common/components/OnboardingCTA";
import { appParamState, barcodeState } from "../../atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { css } from "@emotion/react";
import { getApi } from "../../common/api/ApiFactory";
import { FLOW, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import { EventLogger } from "../../common/utils/EventLogger";

const StyledSwiper = styled(Swiper)`
  background-color: #eff2f5;  
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 120px); // NOTE: keep this in sync with the height of the OnboardingStep
`;

/**
 * Onboarding - The carousel container.
 * OnboardingStep - Represents a slide in carousel, a container which includes a top image and accepts child elements.
 * OnboardingContent - Content within OnboardingStep.
 * OnboardingCTA - Button within OnboardingStep.
 */
function Onboarding() {
  // General params for logic, passed from apps via query param
  const [appParam, setAppParam] = useRecoilState(appParamState);
  const { flow } = appParam;

  const setBarcodeState = useSetRecoilState(barcodeState);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  // For getting active index
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

  // For rendering "dots" indicator
  const [activeIndexRef, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    wireCallbacks();
  });

  useEffect(() => {
    if (flow === "onboarding") {
      redirectLoaded();
      sendAnalytics("onboarding1ScreenOnLoad");
    }
  }, [flow]);

  const onSlideChange = () => {
    const activeIndex = swiperRef?.activeIndex ?? 0;
    setActiveIndex(activeIndex);
    sendAnalytics(`onboarding${activeIndex + 1}ScreenOnLoad`);
  };

  const onSwiperLoad = (swiper: any) => {
    setSwiperRef(swiper);
  };

  const toggleLoading = async () => {
    enableBackgroundOverlay();
    if (loadingState) return;
    let apiError = false;
    setLoadingState(true);
    // Cannot make native callback when an API request is inflight. iOS issue.
    await sendAnalytics("onboarding4AcceptButton");
    await delay(100);
    try {
      const api = getApi(GLOBAL_CONST.CLIENT_ID, GLOBAL_CONST.REGION);
      await api.updateTcAcceptanceForShellRewards();
      const barcodeResponse = await api.getCouponForShellRewards({acceptedTerms: true});
      setBarcodeState(barcodeResponse);
    } catch (e) {
      apiError = true;
      await EventLogger.logErrorEvent("Error occurred while calling Shell/Coupon api", e);
      redirectError();
    }
    if (!apiError) {
      await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/onboarding-complete`);
      setAppParam({
        ...appParam,
        flow: FLOW.REDEEM,
      });
    }
  };

  const nextHandler = () => {
    swiperRef?.slideNext();
  };

  const shellCfg = GLOBAL_CFG[REWARD_OFFERING.SHELL];

  return (
    <>
      <StyledSwiper onSwiper={onSwiperLoad} onSlideChange={onSlideChange} onLoad={onSwiperLoad}>
        <SwiperSlide>
          <OnboardingStep image={shellCfg.onboarding1.image}>
            <OnboardingContent>{parse(replaceGlobalConfigVars(shellCfg.onboarding1.content))}</OnboardingContent>
          </OnboardingStep>
        </SwiperSlide>
        <SwiperSlide>
          <OnboardingStep image={shellCfg.onboarding2.image}>
            <OnboardingContent>{parse(replaceGlobalConfigVars(shellCfg.onboarding2.content))}</OnboardingContent>
          </OnboardingStep>
        </SwiperSlide>
        <SwiperSlide>
          <OnboardingStep image={shellCfg.onboarding3.image}>
            <OnboardingContent>{parse(replaceGlobalConfigVars(shellCfg.onboarding3.content))}</OnboardingContent>
          </OnboardingStep>
        </SwiperSlide>
      </StyledSwiper>

      <OnboardingCTA>
        <div
          css={css`
            padding: 8px 16px 8px 16px;
          `}
        >
          <SwiperDots totalSlides={swiperRef?.slides?.length} activeSlideIndex={activeIndexRef} />

          {activeIndexRef === 2 && (
            <RewardsButton loading={loadingState} clickHandler={toggleLoading}>
              {getPlatformFormattedText("Accept")}
            </RewardsButton>
          )}

          {activeIndexRef < 2 && (
            <RewardsButton clickHandler={nextHandler} variant="secondary">
              {getPlatformFormattedText("Next")}
            </RewardsButton>
          )}
        </div>
      </OnboardingCTA>
    </>
  );
}

export default Onboarding;
