/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import JsBarcode from "jsbarcode";
import { useEffect, useState } from "react";
import { REWARD_OFFERING } from "../../constants";
import { fromPublic, replaceGlobalConfigVars } from "../../utils";

const StyledRedeemBarcodeCard = styled.div`
  position: absolute;
  width: 100%;
  color: #ffffff;
  flex-direction: column;
  display: flex;
  background-color: #14a248;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-bottom: env(safe-area-inset-bottom);

  a {
    font-weight: 700;
    color: #ffffff;
  }
`;

const BottomContainerContent = styled.div`
  padding: 25px 110px 25px 25px;
  position: relative;
  font-weight: 400;
  font-size: 12px;

  img {
    position: absolute;
    right: 16px;
    bottom: -10px;
  }

  h1,
  strong {
    color: white;
  }

  h1 {
    padding-bottom: 0;
  }
`;

const WhiteCard = styled.div`
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
`;

const BarcodeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px;

  img {
    height: 100px;
  }
`;

const BarcodeValue = styled.div`
  font-family: RobotoMono;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.16em;
  color: #2f383c;
`;

const WhiteCardContent = styled.div`
  color: #2f383c;
  padding: 12px;
  background-color: #f5f5f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  p {
    font-weight: 400;
    font-size: 12px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const WhiteCardContentHeader = styled.div`
  display: flex;
  align-items: center;

  img {
    padding-left: 8px;
  }
`;

function RedeemBarcodeCard({ hintOnClick, offsetHeight, barcode }: any) {
  const [barcodeFormatted, setBarcodeFormatted] = useState("");

  useEffect(() => {
    if (barcode) {
      JsBarcode("#barcode", barcode, {
        displayValue: false,
        width: 6,
      });

      let barcodeFormatted = "";
      barcode.split("").forEach((v: string, i: number) => {
        if (i % 4 === 0) {
          barcodeFormatted += " ";
        }
        barcodeFormatted += v;
      });
      setBarcodeFormatted(barcodeFormatted);
    }
  }, [barcode]);

  const shellCfg = GLOBAL_CFG[REWARD_OFFERING.SHELL];

  return (
    <>
      <StyledRedeemBarcodeCard
        css={css`
          min-height: calc(100vh - ${offsetHeight});
        `}
      >
        <BottomContainerContent>
          {parse(replaceGlobalConfigVars(shellCfg.redeem.barcodeCard.topContent))}
          <img src={fromPublic(shellCfg.redeem.barcodeCard.saveAmountLogo)} height={94} />
        </BottomContainerContent>
        <WhiteCard>
          <BarcodeContainer>
            <img id="barcode" />
            <BarcodeValue>{barcodeFormatted}</BarcodeValue>
          </BarcodeContainer>
          <WhiteCardContent>
            <WhiteCardContentHeader>
              {parse(shellCfg.redeem.barcodeCard.bottomTitle)}
              <img src={fromPublic(shellCfg.redeem.barcodeCard.hintCardTriggerIcon)} onClick={hintOnClick} />
            </WhiteCardContentHeader>
            {parse(shellCfg.redeem.barcodeCard.bottomContent)}
          </WhiteCardContent>
        </WhiteCard>
      </StyledRedeemBarcodeCard>
    </>
  );
}

export default RedeemBarcodeCard;
