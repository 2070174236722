import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { REWARD_OFFERING } from "../../constants";

function EuropcarMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={"https://www.europcar.com.au/partners/linkt-member"}
          rewardId={REWARD_OFFERING.europcar}
        />
      </span>
    </>
  );
}

export default EuropcarMain;
