/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";

const StyledOnboardingContent = styled.div`
  background-color: #eff2f5;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  overscroll-behavior: none;
  text-align: center;
`;

function OnboardingContent(props: any) {
  return <StyledOnboardingContent>{props.children}</StyledOnboardingContent>;
}

export default OnboardingContent;
