import axios from "axios";

import { GLOBAL_CONST } from "../../constants";

export class EventLogger {
  static logErrorEvent = async (message: string, error: Error | unknown): Promise<void> => {
    let stackTrace: string | unknown = "";
    let otherInfo: any = {};
    if (error instanceof Error) {
      stackTrace = error.stack;
      otherInfo.message = error.message;
      otherInfo.cause = error.cause;
      otherInfo.name = error.name;
    } else {
      otherInfo.message = JSON.stringify(error);
    }
    await EventLogger.logEvent("ERROR", message, {
      stackTrace,
      otherInfo,
    });
  };
  static logInfoEvent = async (message: string, otherInfo: unknown): Promise<void> => {
    await EventLogger.logEvent("INFO", message, { otherInfo });
  };

  static logEvent = async (eventType: string, message: string, eventData: unknown): Promise<void> => {
    try {
      const url = `${EVENT_LOG_API_ENDPOINT}/event/v1/log`;
      const postData = {
        eventType,
        message,
        feature: "rewards",
        eventData,
      };
      const postConfig = {
        headers: {
          "x-api-key": `${EVENT_LOG_API_KEY}`,
          os: GLOBAL_CONST.DATA["os"],
          "app-name": GLOBAL_CONST.DATA["appName"],
          "app-version": GLOBAL_CONST.DATA["appVersion"],
          "os-version": GLOBAL_CONST.DATA["osVersion"],
          "client-installation-id": GLOBAL_CONST.DATA["clientInstallId"],
        },
      };
      console.log(
        `url = ${url}\npostData = ${JSON.stringify(postData, null, 2)}\npostConfig = ${JSON.stringify(
          postConfig,
          null,
          2,
        )}`,
      );
      await axios.post(url, postData, postConfig);
    } catch (err) {
      console.error(`Fatal error logging event`, err);
    }
  };
}
