/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import "swiper/css";
import { appParamState, barcodeState } from "../../atoms";
import { getApi } from "../../common/api/ApiFactory";
import OnboardingCTA from "../../common/components/OnboardingCTA";
import OnboardingContent from "../../common/components/OnboardingContent";
import OnboardingStep from "../../common/components/OnboardingStep";
import RewardsButton from "../../common/components/RewardsButton";
import { EventLogger } from "../../common/utils/EventLogger";
import { FLOW, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import {
  delay,
  enableBackgroundOverlay,
  getPlatformFormattedText,
  redirect,
  redirectError,
  redirectLoaded,
  replaceGlobalConfigVars,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";

/**
 * Onboarding - Offer Terms screen and calling get coupons for shell rewards on submission.
 */
function Onboarding() {
  // General params for logic, passed from apps via query param
  const [appParam, setAppParam] = useRecoilState(appParamState);
  const { flow } = appParam;

  const rewardId = REWARD_OFFERING.shellfuelup;

  const setBarcodeState = useSetRecoilState(barcodeState);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    wireCallbacks();
  });

  useEffect(() => {
    if (flow === "onboarding") {
      redirectLoaded();
      sendAnalytics("onboarding1ScreenOnLoad");
    }
  }, [flow]);

  const toggleLoading = async () => {
    enableBackgroundOverlay();
    if (loadingState) return;
    let apiError = false;
    setLoadingState(true);
    // Cannot make native callback when an API request is inflight. iOS issue.
    await sendAnalytics("onboarding1AcceptButton");
    await delay(100);
    try {
      const api = getApi(GLOBAL_CONST.CLIENT_ID, GLOBAL_CONST.REGION);
      await api.updateTcAcceptanceForShellRewards();
      const barcodeResponse = await api.getCouponForShellRewards({ acceptedTerms: true, rewardId });
      setBarcodeState(barcodeResponse);
    } catch (e) {
      apiError = true;
      await EventLogger.logErrorEvent("Error occurred while calling Shell Fuel Up /Coupon api", e);
      redirectError();
    }
    if (!apiError) {
      await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/onboarding-complete`);
      setAppParam({
        ...appParam,
        flow: FLOW.REDEEM,
      });
    }
  };

  const BottomContainer = styled.div`
    position: relative;
  `;

  return (
    <>
      <OnboardingStep
        image={GLOBAL_CFG[rewardId].onboarding1.image}
        imageLottie={GLOBAL_CFG[rewardId].onboarding1.lottie}
      >
        <OnboardingContent>
          {parse(replaceGlobalConfigVars(GLOBAL_CFG[rewardId].onboarding1.content))}
        </OnboardingContent>
      </OnboardingStep>

      <BottomContainer>
        <OnboardingCTA includeOffset={false}>
          <div
            css={css`
              padding: 8px 16px 8px 16px;
            `}
          >
            <RewardsButton clickHandler={toggleLoading} loading={loadingState}>
              {getPlatformFormattedText(GLOBAL_CFG[rewardId].onboarding1.ctaText ?? "Accept")}
            </RewardsButton>
          </div>
        </OnboardingCTA>
      </BottomContainer>
    </>
  );
}

export default Onboarding;
