/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { appParamState, barcodeState } from "../../atoms";
import { getApi } from "../../common/api/ApiFactory";
import ImageLottie from "../../common/components/ImageLottie";
import { EventLogger } from "../../common/utils/EventLogger";
import { FLOW, GLOBAL_CONST, PLACEHOLDER_BARCODE, REWARD_OFFERING } from "../../constants";
import {
  fromPublic,
  redirect,
  redirectError,
  redirectLoaded,
  replaceGlobalConfigVars,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";
import RedeemBarcodeCard from "./RedeemBarcodeCard";
import RedeemHintCard from "./RedeemHintCard";

const StyledRedeem = styled.div`
  flex-direction: column;
  display: flex;
  height: calc(100vh - env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
`;

const TopContainer = styled.div`
  padding: 21px;
  text-align: center;
`;

const TopHeader = styled.div`
  font-size: 10px;
  padding-bottom: 10px;
`;

// overwrite existing styles
const RedeemContent = styled.div`
  padding-top: 50px;
  padding-bottom: 40px;

  h1 {
    font-weight: 600;
  }

  div {
    padding: 20px 50px;
  }
`;

const BottomContainer = styled.div`
  position: relative;
`;

const FaqTag = styled.img`
  position: fixed;
  right: 0;
  top: calc(24px + env(safe-area-inset-top, 0px));
`;

/**
 * Redeem - Parent container for all components. Split into top and bottom container. Top container content implement here.
 * RedeemBarcodeCard - Child component which does into the bottom container of Redeem. Main component for displaying barcode.
 * RedeemHintCard - Fixed component which resides after Redeem. Displays an overlay bottom sheet that mimics the mobile app.
 */
function Redeem() {
  const { flow } = useRecoilValue(appParamState);
  // const [loader, setLoader] = useRecoilState(loadedState);
  const [barcode, setBarcode] = useRecoilState(barcodeState);
  const [showHint, setShowHint] = useState(false);
  const [showLottieImage, setShowLottieImage] = useState(false);
  const [topContainerHeight, setTopContainerHeight] = useState("0px");
  const topElementRef = useRef<HTMLDivElement>(null);

  const rewardId = REWARD_OFFERING.shellfuelup;
  const shellFuelUpCfg = GLOBAL_CFG[rewardId];

  useEffect(() => {
    wireCallbacks();
  });

  useEffect(() => {
    if (flow === FLOW.REDEEM) {
      (async () => {
        try {
          // Barcode could be retrieved from onboarding already.
          if (barcode === PLACEHOLDER_BARCODE) {
            const api = getApi(GLOBAL_CONST.CLIENT_ID, GLOBAL_CONST.REGION);
            const barcodeResponse = await api.getCouponForShellRewards({ rewardId });
            setBarcode(barcodeResponse);
          }
          await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/max-brightness`);
          setShowLottieImage(true); // show lottie image on successfull barcode retrieval
          redirectLoaded();
          await sendAnalytics("redeemScreenOnLoad");
        } catch (e) {
          await EventLogger.logErrorEvent("Error occurred viewing the Shell Fuel Up barcode", e);
          redirectError();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  // A bit janky, but we need to determine the height for various calculations.
  useEffect(() => {
    if (topElementRef.current) {
      setTopContainerHeight(`${topElementRef.current.offsetHeight}px`);
    }
  }, [topElementRef.current?.offsetHeight, flow]);

  const toggleShowHint = () => {
    if (showHint) {
      sendAnalytics("redeemTroubleScanningDismiss");
    } else {
      sendAnalytics("redeemTroubleScanning");
    }
    setShowHint(!showHint);
  };

  const faqTagClick = () => {
    redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/faq`);
    sendAnalytics("redeemFaqTag");
  };

  return (
    <>
      {showLottieImage && <ImageLottie imageLottie={shellFuelUpCfg.redeem.lottie} fullScreen={true}></ImageLottie>}
      <FaqTag src={fromPublic(shellFuelUpCfg.redeem.faqTag)} onClick={faqTagClick}></FaqTag>
      <StyledRedeem
        css={css`
          overflow-y: ${showHint ? "hidden" : "auto"};
        `}
      >
        <TopContainer ref={topElementRef}>
          <TopHeader>in partnership with</TopHeader>
          <img src={fromPublic(shellFuelUpCfg.redeem.logo)} />
          <img src={fromPublic(shellFuelUpCfg.redeem.logo2)} />
          <RedeemContent>{parse(replaceGlobalConfigVars(shellFuelUpCfg.redeem.content))}</RedeemContent>
        </TopContainer>
        <BottomContainer>
          <RedeemBarcodeCard hintOnClick={toggleShowHint} offsetHeight={topContainerHeight} barcode={barcode} />
        </BottomContainer>
      </StyledRedeem>
      <RedeemHintCard hintOnClick={toggleShowHint} offsetHeight={topContainerHeight} showHint={showHint} />
    </>
  );
}

export default Redeem;
