/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { css } from "@emotion/react";

const StyledOnboardingCTA = styled.div`
  z-index: 80;
  position: fixed;
  background-color: #eff2f5;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
`;

function OnboardingCTA({ children, includeOffset = true }: any) {
  return (
    <StyledOnboardingCTA
      css={css`
        height: ${includeOffset ? "110px" : "70px"};
      `}
    >
      {children}
    </StyledOnboardingCTA>
  );
}

export default OnboardingCTA;
