import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import {GLOBAL_CONST, REWARD_OFFERING} from "../../constants";

function SecureParkingMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={`https://www.secureparking.com.au/linktcustomerrewards/?id=${GLOBAL_CONST.DATA["externalId"] || ''}`}
          rewardId={REWARD_OFFERING.secureparking}
        />
      </span>
    </>
  );
}

export default SecureParkingMain;
