import { CLIENT, REGION } from "../../constants";
import { BffApi } from "./BffApi";
import { GoBosApi } from "./GoBosApi";
import { TuapiApi } from "./TuapiApi";

// To be implemented by client specific api classes. These classes handle the unique requirements of each client's backend
// NOTE: concrete classes to not be used directly. To be accessed via factory method below
export interface Api {
  updateTcAcceptanceForShellRewards: () => Promise<void>;
  getCouponForShellRewards: (params?: {acceptedTerms?: boolean, rewardId?: string}) => Promise<string>;
}

// Factory method to get client specific api object.
export const getApi = (client: CLIENT, region: REGION): Api => {
  if (client === CLIENT.LINKTGO) {
    return new GoBosApi();
  } else if (client === CLIENT.LINKT) {
    return region === "vic" ? new BffApi() : new TuapiApi();
  } else {
    throw Error("Could not determine correct API");
  }
};
