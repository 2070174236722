import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import {GLOBAL_CONST, REWARD_OFFERING} from "../../constants";

function BookingDotComMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={`https://www.booking.com/gating/authkey?aid=2383732&key=0vCsbfPC6t&label=${GLOBAL_CONST.DATA["externalId"]}`}
          rewardId={REWARD_OFFERING.bookingdotcom}
        />
      </span>
    </>
  );
}

export default BookingDotComMain;
