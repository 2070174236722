import React from "react";
import { useEffect, useState } from "react";
import { RewardsHubServicesApi } from "../../common/api/RewardsHubServicesApi";
import { CLIENT, COUPON_OFFERS, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import { EventLogger } from "../../common/utils/EventLogger";
import { redirectError } from "../../utils";
import OnboardingPageExternalCouponLink from "../../common/components/OnboardingPageExternalCouponLink";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";

function GlobalEscapesMain() {
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    async function getCoupon() {
      const api = new RewardsHubServicesApi();
      try {
        const coupon = await api.getCoupon({
          accountId: GLOBAL_CONST.DATA["accountId"],
          offerId: COUPON_OFFERS.globalescapes,
        });
        setCoupon(coupon.couponCode);
      } catch (err) {
        await EventLogger.logErrorEvent("Error occured while fetching coupon:", err);
        redirectError();
      }
      setLoading(false);
    }

    if (GLOBAL_CONST.CLIENT_ID !== CLIENT.LINKTGO) {
      getCoupon();
    }
  }, []);

  const redeemUrl = `https://globalescapes.com.au/?utm_source=linktrewards${GLOBAL_CONST.DATA["externalId"] ? "&eid=" + GLOBAL_CONST.DATA["externalId"] : ""}`;

  // Display the old reward for LinktGO.
  if (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKTGO) {
    return (
      <>
        <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
          <OnboardingPageExternalLink redeemUrl={redeemUrl} rewardId={REWARD_OFFERING.globalescapes} />
        </span>
      </>
    );
  }

  return (
    <>
      {!loading && (
        <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
          <OnboardingPageExternalCouponLink
            scaCfg={GLOBAL_CFG["global-escapes-coupon"]}
            redeemUrl={redeemUrl}
            coupon={coupon}
          />
        </span>
      )}
    </>
  );
}

export default GlobalEscapesMain;
