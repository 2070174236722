/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import "swiper/css";
import {
  getPlatformFormattedText,
  redirect,
  redirectLoaded,
  replaceGlobalConfigVars,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";
import OnboardingStep from "../../common/components/OnboardingStep";
import parse from "html-react-parser";
import RewardsButton from "../../common/components/RewardsButton";
import OnboardingContent from "../../common/components/OnboardingContent";
import OnboardingCTA from "../../common/components/OnboardingCTA";
import { css } from "@emotion/react";
import { CLIENT, FLOW, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { appParamState } from "../../atoms";
import semver from "semver";

type OnboardingPageParams = {
  redeemUrl: string;
  rewardId: REWARD_OFFERING;
};

/**
 * This component is to cater for the reward partners that have a single onboarding page and a link out to
 * the partner's reward site
 * @param redeemUrl the redemption url
 * @param rewardId The reward partner internal ID
 */
function OnboardingPageExternalLink({ redeemUrl, rewardId }: OnboardingPageParams) {
  const { flow } = useRecoilValue(appParamState);
  const redeemUrlEncoded = encodeURIComponent(redeemUrl);

  useEffect(() => {
    wireCallbacks();
  });

  useEffect(() => {
    // currently, we want to show the same screen for onboarding and redeem for these flows
    if (flow === FLOW.ONBOARDING || flow === FLOW.REDEEM) {
      redirectLoaded();
      sendAnalytics("onboarding1ScreenOnLoad");
      redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/onboarding-complete`);
    }
  }, [flow]);

  const isLegacy = () => {
    // Hard coding versions as don't see point in putting in external config file. Either way, will require a release if they ever chance (which is unlikely).
    const isLinktLegacy = semver.lte(GLOBAL_CONST.APP_VERSION, "4.0.0");
    const isLinktgoLegacy = semver.lte(GLOBAL_CONST.APP_VERSION, "3.0.1");
    return (
      (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKT && isLinktLegacy) ||
      (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKTGO && isLinktgoLegacy)
    );
  };

  const toggleLoading = async () => {
    await sendAnalytics("onboarding1AcceptButton");
    // older version of the app can't handle additional params in the link callback.
    if (isLegacy()) {
      await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/link?url=${redeemUrlEncoded}`);
    } else {
      await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/link?url=${redeemUrlEncoded}&view-type=tab`);
    }
  };

  const BottomContainer = styled.div`
    position: relative;
  `;

  return (
    <>
      <OnboardingStep
        image={GLOBAL_CFG[rewardId].onboarding1.image}
        imageLottie={GLOBAL_CFG[rewardId].onboarding1.lottie}
      >
        <OnboardingContent>
          {parse(replaceGlobalConfigVars(GLOBAL_CFG[rewardId].onboarding1.content))}
        </OnboardingContent>
      </OnboardingStep>

      <BottomContainer>
        <OnboardingCTA includeOffset={false}>
          <div
            css={css`
              padding: 8px 16px 8px 16px;
            `}
          >
            <RewardsButton clickHandler={toggleLoading} icon={"externalLink"}>
              {getPlatformFormattedText(GLOBAL_CFG[rewardId].onboarding1.ctaText ?? "Redeem")}
            </RewardsButton>
          </div>
        </OnboardingCTA>
      </BottomContainer>
    </>
  );
}

export default OnboardingPageExternalLink;
