import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useIsMounted } from "usehooks-ts";
import { appParamState } from "../../atoms";
import { FLOW, GLOBAL_CONST } from "../../constants";
import Onboarding from "./Onboarding";
import Redeem from "./Redeem";

function ShellFuelUpMain() {
  const [appParam, setAppParam] = useRecoilState(appParamState);

  const isMounted = useIsMounted();

  // Determine the view height so carousel slides can be set correctly. Mostly for Safari on mobile.
  useEffect(() => {
    if (!GLOBAL_CONST.ACCESS_TOKEN) {
      throw new Error("Shell Fuel Up rewards - Unauthenticated (no access token exists)");
    }
  }, [isMounted, setAppParam]);

  // Actually pre-render both onboarding and redeem on the page, with one hidden. Better webview experience preloading the elements.
  const showRedeem = appParam.flow === FLOW.REDEEM; // || true;
  return (
    <>
      <span style={{ display: showRedeem ? "none" : "block", height: "calc(100vh - 55px)" }}>
        <Onboarding />
      </span>
      <span style={{ display: showRedeem ? "block" : "none", height: "calc(100vh - 55px)" }}>
        <Redeem />
      </span>
    </>
  );
}

export default ShellFuelUpMain;
