/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import Lottie from "lottie-react";
import { fromPublic, getLottieJson } from "../../utils";

const StyledOnboardingStep = styled.div`
  background-color: #eff2f5;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  text-align: center;
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 120px
  ); // cater for the height of the CTA bottom button, and safe area insets
`;

const ImageContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

function OnboardingStep({ image, imageLottie, children }: any) {
  return (
    <StyledOnboardingStep>
      <ImageContainer>
        {imageLottie && <Lottie animationData={getLottieJson(fromPublic(imageLottie))} loop={false} />}
        {!imageLottie && <img alt="" src={fromPublic(image)}></img>}
      </ImageContainer>

      {children}
    </StyledOnboardingStep>
  );
}

export default OnboardingStep;
