import React from "react";
import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { GLOBAL_CONST, REWARD_OFFERING } from "../../constants";

function CompWinChooseFlyMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={`https://www.linkt.com.au/campaigns/fly?${
            GLOBAL_CONST.DATA["externalId"] ? "&cid=" + GLOBAL_CONST.DATA["externalId"] : ""
          }`}
          rewardId={REWARD_OFFERING.compwinchoosefly}
        />
      </span>
    </>
  );
}

export default CompWinChooseFlyMain;
