import styled from "@emotion/styled";

const StyledSwiperDots = styled.div`
  background-color: #eff2f5;
  height: 30px;
  width: 100%;
  z-index: 100;
`;

const Container = styled.div`
  margin-top: 15px;
  text-align: center;

  .carousel-dot {
    margin: 3px;
    background-color: #c2d1d0;
    border-radius: 5px;
    display: inline-block;
    height: 8px;
    width: 8px;
  }

  .carousel-dot.active {
    background-color: #0a4842;
  }
`;

const generateDots = ({ totalSlides, activeSlideIndex }: any) => {
  const elements = [];
  for (let i = 0; i < totalSlides; i++) {
    elements.push(
      <span key={`dot-${i}`} className={`${activeSlideIndex === i ? "carousel-dot active" : "carousel-dot"}`}></span>,
    );
  }
  return elements;
};

function SwiperDots(props: any) {
  return (
    <StyledSwiperDots>
      <Container>{generateDots(props).map((d) => d)}</Container>
    </StyledSwiperDots>
  );
}

export default SwiperDots;
