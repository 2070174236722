import OnboardingPageExternalLink from "../../common/components/OnboardingPageExternalLink";
import { GLOBAL_CONST, REWARD_OFFERING } from "../../constants";

function MyCarTacticalMain() {
  return (
    <>
      <span style={{ display: "block", height: "calc(100vh - 55px)" }}>
        <OnboardingPageExternalLink
          redeemUrl={`https://www.mycar.com.au/offers/transurbantactical/?region=${GLOBAL_CONST.REGION}&eid=${
            GLOBAL_CONST.DATA["externalId"] || ""
          }`}
          rewardId={REWARD_OFFERING.mycartactical}
        />
      </span>
    </>
  );
}

export default MyCarTacticalMain;
