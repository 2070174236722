import { atom } from "recoil";
import { GLOBAL_CONST, PLACEHOLDER_BARCODE } from "./constants";

export const appParamState = atom({
  key: "appParam",
  default: {
    flow: GLOBAL_CONST.FLOW_PENDING,
  },
});

export const barcodeState = atom({
  key: "barcode",
  default: PLACEHOLDER_BARCODE,
});

export const loadedState = atom({
  key: "loaded",
  default: false,
});
